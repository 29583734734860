var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-form-group',{staticClass:"mb-4",attrs:{"label-cols":"0","content-cols":"12","label-cols-md":"3","content-cols-md":"9","label":_vm.$t('TL_SHARED_DOCUMENTS'),"label-size":"lg","label-for":"selCitationStyle","label-class":"font-weight-bold pt-0"}},[_c('b-form-group',{attrs:{"label":_vm.$t('TL_EXPORT_DOCUMENT_GRANULARITY_HOW_MANY')}},[_c('div',{staticClass:"mt-2 ml-4"},[_c('b-form-radio',{attrs:{"data-cy":"exportdocumentmodesinglecheckbox","size":"lg","checked":_vm.ExportDocumentMode,"value":1},on:{"change":function($event){_vm.ExportDocumentMode = 1}}},[_vm._v("\n          "+_vm._s(_vm.$t('TL_DOCUMENT_GRANULARITY_SINGLE_DOCUMENT', {
              documenttype: _vm.$t(
                ("TL_INTEGRATION_" + _vm.finalDocumentType + "_DOCUMENTNAME")
              ),
            })))]),_vm._v(" "),_c('exportoptionsdocumentgranularityicons',{staticClass:"mt-2 ml-5 mb-3",attrs:{"multiplefiles":false}}),_vm._v(" "),_c('b-form-radio',{attrs:{"data-cy":"exportdocumentmodebookcheckbox","size":"lg","checked":_vm.ExportDocumentMode,"value":2},on:{"change":function($event){_vm.ExportDocumentMode = 2}}},[_vm._v("\n          "+_vm._s(_vm.$t('TL_DOCUMENT_GRANULARITY_MULTIPLE_DOCUMENTS', {
              documenttype: _vm.$t(
                ("TL_INTEGRATION_" + _vm.finalDocumentType + "_DOCUMENTNAME")
              ),
            }))+"\n        ")]),_vm._v(" "),_c('exportoptionsdocumentgranularityicons',{staticClass:"mt-2 ml-5 mb-3",attrs:{"multiplefiles":true}})],1)]),_vm._v(" "),(
        _vm.selectedIntegration.IntegrationExportSettings
          .ExportDocumentMode === 1 &&
        (_vm.showStartBookOnANewPage || _vm.showStartBookWithBookTitle)
      )?_c('b-form-group',{staticClass:"mb-0 mt-2",attrs:{"label":_vm.$t('TL_EXPORT_DOCUMENT_GRANULARITY_SINGLE_FILE')}},[_c('div',{staticClass:"ml-4"},[(_vm.showStartBookOnANewPage)?_c('b-form-checkbox',{attrs:{"size":"lg","inline":"","checked":_vm.StartBookOnNewPage},on:{"change":function($event){_vm.StartBookOnNewPage = $event}}},[_vm._v(_vm._s(_vm.$t('TL_SHARED_OPTIONS_START_BOOK_ON_NEW_PAGE')))]):_vm._e(),_vm._v(" "),(_vm.showStartBookWithBookTitle)?_c('b-form-checkbox',{attrs:{"inline":"","size":"lg","checked":_vm.StartBookWithBookTitle},on:{"change":function($event){_vm.StartBookWithBookTitle = $event}}},[_vm._v(_vm._s(_vm.$t('TL_SHARED_OPTIONS_START_BOOK_WITH_TITLE')))]):_vm._e()],1)]):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }