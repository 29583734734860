








































import ExportUiStateModule from '@/store/modules/ExportUiStateModule';
import { Component, Vue } from 'vue-property-decorator';
import UserSettingsModule from '@/store/modules/UserSettingsModule';

@Component
export default class ExportOptionsIntegrationAutoExport extends Vue {
  get AutoExport() {
    return ExportUiStateModule.selectedIntegration!
      .IntegrationExportSettings.AutoExport;
  }

  set AutoExport(value: boolean) {
    UserSettingsModule.saveIntegrationExportSettings({
      settingName: 'AutoExport',
      settingValue: value,
      modal: this.$bvModal,
      showResetPrompt: false,
    });
  }

  get exportedRecordCount() {
    return UserSettingsModule.exportedRecordCount(
      ExportUiStateModule.selectedIntegration!.IntegrationID,
      ExportUiStateModule.selectedExportFormatIntegration!
        .IntegrationID,
    );
  }
  get selectedIntegration() {
    return ExportUiStateModule.selectedIntegration;
  }
}
