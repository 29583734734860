

























import { Component, Vue } from 'vue-property-decorator';
import i18n from '@/i18n';
import UserSettingsModule from '@/store/modules/UserSettingsModule';
import ExportUiStateModule from '@/store/modules/ExportUiStateModule';
import { IIntegration } from '@/store/types/IIntegrations';

@Component
export default class ExportOptionsSorting extends Vue {
  sortOrderOptions = [
    {
      text: i18n.t('TL_SORT_DIRECTION_LOCATION_ASC'),
      value: 'LocationDecimal Asc',
      Icon: '&#xf062;',
    },
    {
      text: i18n.t('TL_SORT_DIRECTION_LOCATION_DESC'),
      value: 'LocationDecimal Desc',
      Icon: '&#xf063;',
    },
    {
      text: i18n.t('TL_SORT_DIRECTION_HIGHLIGHT_CREATED_ASC'),
      value: 'CreatedKindle Asc',
      Icon: '&#xf062;',
    },
    {
      text: i18n.t('TL_SORT_DIRECTION_HIGHLIGHT_CREATED_DESC'),
      value: 'CreatedKindle Desc',
      Icon: '&#xf063;',
    },
  ];

  get SortOrder() {
    return ExportUiStateModule.selectedIntegration!
      .IntegrationExportSettings.SortOrder;
  }

  set SortOrder(value: string) {
    UserSettingsModule.saveIntegrationExportSettings({
      settingName: 'SortOrder',
      settingValue: value,
      modal: this.$bvModal,
      showResetPrompt: true,
    });
  }

  get integrationHasBeenSelected() {
    return ExportUiStateModule.integrationHasBeenSelected;
  }

  // Only used to be watched
  get selectedIntegration(): IIntegration | undefined {
    return ExportUiStateModule.selectedIntegration;
  }
}
