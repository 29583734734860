















































































































import ExportUiStateModule from '@/store/modules/ExportUiStateModule';
import { Component, Vue } from 'vue-property-decorator';
import UserSettingsModule from '@/store/modules/UserSettingsModule';

@Component
export default class ExportOptionsIntegrationsExportedRecords extends Vue {
  get exportedRecordCountString() {
    return Number(
      UserSettingsModule.exportedRecordCount(
        ExportUiStateModule.selectedIntegration!.IntegrationID,
        ExportUiStateModule.selectedExportFormatIntegration!
          .IntegrationID,
      ),
    ).toLocaleString();
  }
  get exportedRecordCount() {
    return UserSettingsModule.exportedRecordCount(
      ExportUiStateModule.selectedIntegration!.IntegrationID,
      ExportUiStateModule.selectedExportFormatIntegration!
        .IntegrationID,
    );
  }

  get selectedIntegration() {
    return ExportUiStateModule.selectedIntegration;
  }

  get selectedExportFormatIntegration() {
    return ExportUiStateModule.selectedExportFormatIntegration;
  }

  get IsIntegrationWhichSupportsStoringFiles() {
    return ExportUiStateModule.selectedIntegration!
      .IsIntegrationWhichSupportsStoringFiles;
  }

  async resetAllExportedRecordsForIntegration(integrationId: number) {
    await UserSettingsModule.resetAllExportedRecordsForIntegration(
      integrationId,
    );
  }

  async resetExportedRecordsForFileStorageIntegration(
    integrationId: number,
    exportFormatIntegrationid: number,
  ) {
    await UserSettingsModule.resetExportedRecordsForFileStorageIntegration(
      { integrationId, exportFormatIntegrationid },
    );
  }
}
