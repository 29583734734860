













































































import { Component, Prop, Vue } from 'vue-property-decorator';
import ExplorerUiStateModule from '@/store/modules/ExplorerUiStateModule';
import ExportUiStateModule from '@/store/modules/ExportUiStateModule';

@Component
export default class ExportOptionsDocumentGranularityIcons extends Vue {
  @Prop() multiplefiles!: boolean;
  get selectedfolder() {
    return ExplorerUiStateModule.selectedFolder;
  }

  get integrationName() {
    return ExportUiStateModule.selectedIntegration!.IntegrationName;
  }

  get exportFormatIntegrationName() {
    return ExportUiStateModule.selectedExportFormatIntegration!
      .IntegrationName;
  }

  get IsIntegrationWhichSupportsStoringFiles() {
    return ExportUiStateModule.selectedIntegration!
      .IsIntegrationWhichSupportsStoringFiles;
  }
}
