















import { Component, Vue, PropSync } from 'vue-property-decorator';
import exportoptionsadvancedformatting from '@/pages/export/components/ExportOptionsAdvancedFormatting.vue';

@Component({
  components: {
    exportoptionsadvancedformatting,
  },
})
export default class ExportOptionsModal extends Vue {
  @PropSync('visible') isVisible!: boolean;
}
