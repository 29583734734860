















































































import { Component, Vue } from 'vue-property-decorator';
import ExportUiStateModule from '@/store/modules/ExportUiStateModule';
import UserSettingsModule from '@/store/modules/UserSettingsModule';
import { IIntegration } from '@/store/types/IIntegrations';
@Component
export default class ExportOptionsRecordTypes extends Vue {
  // https://stackoverflow.com/questions/42686388/how-to-bind-checkboxes-to-vuex-store

  get IncludeBookmarks() {
    return ExportUiStateModule.selectedIntegration!
      .IntegrationExportSettings.BookMarks;
  }

  set IncludeBookmarks(value: boolean) {
    UserSettingsModule.saveIntegrationExportSettings({
      settingName: 'BookMarks',
      settingValue: value,
      modal: this.$bvModal,
      showResetPrompt: true,
    });
  }

  get IncludeHighlights() {
    return ExportUiStateModule.selectedIntegration!
      .IntegrationExportSettings.Highlights;
  }

  set IncludeHighlights(value: boolean) {
    UserSettingsModule.saveIntegrationExportSettings({
      settingName: 'Highlights',
      settingValue: value,
      modal: this.$bvModal,
      showResetPrompt: true,
    });
  }

  get IncludeNotes() {
    return ExportUiStateModule.selectedIntegration!
      .IntegrationExportSettings.Notes;
  }

  set IncludeNotes(value: boolean) {
    UserSettingsModule.saveIntegrationExportSettings({
      settingName: 'Notes',
      settingValue: value,
      modal: this.$bvModal,
      showResetPrompt: true,
    });
  }

  get IncludeTags() {
    return ExportUiStateModule.selectedIntegration!
      .IntegrationExportSettings.IncludeTags;
  }

  set IncludeTags(value: boolean) {
    UserSettingsModule.saveIntegrationExportSettings({
      settingName: 'IncludeTags',
      settingValue: value,
      modal: this.$bvModal,
      showResetPrompt: true,
    });
  }

  get IncludeClippings() {
    return ExportUiStateModule.selectedIntegration!
      .IntegrationExportSettings.Clippings;
  }

  set IncludeClippings(value: boolean) {
    UserSettingsModule.saveIntegrationExportSettings({
      settingName: 'Clippings',
      settingValue: value,
      modal: this.$bvModal,
      showResetPrompt: true,
    });
  }

  get selectedIntegration(): IIntegration | undefined {
    return ExportUiStateModule.selectedIntegration;
  }
}
