







































import ExportUiStateModule from '@/store/modules/ExportUiStateModule';
import { Component, Vue } from 'vue-property-decorator';
import exportoptionsdocumentgranularityicons from '@/pages/export/components/ExportOptionsDocumentGranularityIcons.vue';
import UserSettingsModule from '@/store/modules/UserSettingsModule';
import i18n from '@/i18n';

@Component({
  components: {
    exportoptionsdocumentgranularityicons,
  },
})
export default class ExportOptionsIntegrationFileType extends Vue {
  //resetExportedRecords = ExportUiStateModule.resetExportedRecords;
  //todo think I can replace watch with prop sync

  ExportFormatIntegrationOptions = [
    { value: 8, text: i18n.t('TL_SHARED_OPTIONS_EXCEL_2003') },
    { value: 9, text: i18n.t('TL_SHARED_OPTIONS_EXCEL_2007') },
    { value: 11, text: i18n.t('TL_SHARED_OPTIONS_PDF') },
    { value: 12, text: i18n.t('TL_SHARED_OPTIONS_TEXT') },
    { value: 10, text: i18n.t('TL_SHARED_OPTIONS_WORD_2007') },
    { value: 13, text: i18n.t('TL_SHARED_OPTIONS_WORD_2003') },
    {
      value: 14,
      text: i18n.t('TL_SHARED_OPTIONS_OPEN DOCUMENT_TEXT'),
    },
    {
      value: 15,
      text: i18n.t('TL_SHARED_OPTIONS_OPEN DOCUMENT_SPREADSHEET'),
    },
    {
      value: 16,
      text: i18n.t('TL_SHARED_OPTIONS_MARKDOWN'),
    },
    {
      value: 17,
      text: i18n.t('TL_SHARED_OPTIONS_JSON'),
    },
  ];

  get selectedExportFormatIntegration() {
    return ExportUiStateModule.selectedExportFormatIntegration;
  }

  get ExportFormatIntegrationID() {
    return ExportUiStateModule.selectedIntegration!
      .IntegrationExportSettings.ExportFormatIntegrationID;
  }

  set ExportFormatIntegrationID(value: number) {
    UserSettingsModule.saveIntegrationExportSettings({
      settingName: 'ExportFormatIntegrationID',
      settingValue: value,
      modal: this.$bvModal,
      showResetPrompt: false,
    });
  }

  get selectedIntegration() {
    return ExportUiStateModule.selectedIntegration;
  }
}
