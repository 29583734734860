







































import ExplorerDataModule from '@/store/modules/ExplorerDataModule';
import { IIntegration } from '@/store/types/IIntegrations';
import {
  Component,
  Vue,
  PropSync,
  Prop,
} from 'vue-property-decorator';

@Component
export default class IntegrationConnectedModal extends Vue {
  @PropSync('visible') isVisible!: boolean;
  @Prop() readonly integration!: IIntegration;
}
