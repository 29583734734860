











































































































































import ExportUiStateModule from '@/store/modules/ExportUiStateModule';
import { Component, Vue } from 'vue-property-decorator';
import UserSettingsModule from '@/store/modules/UserSettingsModule';

@Component
export default class ExportOptionsIntegrationPublishMode extends Vue {
  get ExportPublishMode() {
    return ExportUiStateModule.selectedIntegration!
      .IntegrationExportSettings.ExportPublishMode;
  }

  set ExportPublishMode(value: number) {
    UserSettingsModule.saveIntegrationExportSettings({
      settingName: 'ExportPublishMode',
      settingValue: value,
      modal: this.$bvModal,
      showResetPrompt: true,
    });
  }

  get selectedIntegration() {
    return ExportUiStateModule.selectedIntegration;
  }

  get finalDocumentType() {
    return ExportUiStateModule.selectedExportFormatIntegration?.IntegrationName.toUpperCase();
  }
}
