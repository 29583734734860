















































































































import { Component, Vue } from 'vue-property-decorator';
import ExportUiStateModule from '@/store/modules/ExportUiStateModule';

@Component
export default class ExportOptionsContentPreview extends Vue {
  examplerecord = {
    Content:
      'In this world, shipmates, sin that pays its way can travel freely and without a passport; whereas Virtue, if a pauper, is stopped at all frontiers.',
    Book: 'Moby Dick',
    Author: 'Herman Melville',
    Created: '1/1/2018',
    Location: '2-105',
    Page: 9,
    Notes: `Notes: 1) Taken with the context this is full of meaning 2) I'll sail with you`,
    Tags: 'Tags: English, Revision',
  };

  get selectedIntegration() {
    return ExportUiStateModule.selectedIntegration;
  }
}
