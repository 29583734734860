






























































































import { Component, Vue, Prop, Emit } from 'vue-property-decorator';
import integrationsselectoritem from '@/components/integrationsselectoritem.vue';
import { IIntegration } from '@/store/types/IIntegrations';

@Component({
  components: {
    integrationsselectoritem,
  },
})
export default class Integrationsselector extends Vue {
  @Prop({ default: true })
  readonly showintegrationsonly!: boolean;
  @Prop({ default: [] }) readonly integrations!: IIntegration[];
  @Prop() readonly selectedintegrationId!: number;
  @Emit()
  selectintegration(integration: IIntegration) {
    return integration;
  }
  @Emit()
  connectexportintegration(integrationID: number) {
    return integrationID;
  }

  get integrationspublish(): IIntegration[] {
    return this.integrations.filter(
      (i: IIntegration) => i.IntegrationTypeID === 1 && i.Enabled,
    );
  }

  get integrationsdownloads(): IIntegration[] {
    return this.integrations.filter(
      (i: IIntegration) => i.IntegrationTypeID === 2 && i.Enabled,
    );
  }
}
