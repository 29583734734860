var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-form-group',{staticClass:"mb-4",attrs:{"label-cols":"0","content-cols":"12","label-cols-md":"3","content-cols-md":"9","label":_vm.$t('TL_SHARED_HISTORY'),"label-size":"lg","label-for":"selCitationStyle","label-class":"font-weight-bold pt-0"}},[(
        _vm.selectedIntegration.IsIntegrationWhichSupportsStoringFiles
      )?_c('p',[_vm._v("\n      "+_vm._s(_vm.$t(
          'TL_SHARED_OPTIONS_YOU_HAVE_EXPORTED_TO_THIS_INTEGRATION_IN_FORMAT',
          {
            integration:
              _vm.selectedIntegration.IntegrationFriendlyName,
            count: _vm.exportedRecordCountString,
            fileType:
              _vm.selectedExportFormatIntegration.IntegrationFriendlyName,
          }
        ))+"\n    ")]):_c('p',[_vm._v("\n      "+_vm._s(_vm.$t(
          'TL_SHARED_OPTIONS_YOU_HAVE_EXPORTED_TO_THIS_INTEGRATION',
          {
            integration:
              _vm.selectedIntegration.IntegrationFriendlyName,
            count: _vm.exportedRecordCountString,
          }
        ))+"\n    ")]),_vm._v(" "),(
        _vm.exportedRecordCount > 0 &&
        _vm.IsIntegrationWhichSupportsStoringFiles
      )?_c('b-button',{staticClass:"ml-4",attrs:{"data-cy":'resetexportedrecords-' +
        _vm.selectedIntegration.IntegrationFriendlyName,"squared":"","variant":"dark","size":"sm"},on:{"click":function($event){return _vm.resetExportedRecordsForFileStorageIntegration(
          _vm.selectedIntegration.IntegrationID,
          _vm.selectedIntegration.IntegrationExportSettings
            .ExportFormatIntegrationID
        )}}},[_vm._v("\n      "+_vm._s(_vm.$t(
          'TL_SHARED_OPTIONS_REEXPORT ALL_EXPORTED_TO_INTEGRATION_AND_FILETYPE',
          {
            integration:
              _vm.selectedIntegration.IntegrationFriendlyName,
            fileType:
              _vm.selectedExportFormatIntegration.IntegrationFriendlyName,
          }
        ))+"\n    ")]):_vm._e(),_vm._v(" "),(
        _vm.exportedRecordCount > 0 &&
        !_vm.IsIntegrationWhichSupportsStoringFiles
      )?_c('b-button',{staticClass:"ml-4",attrs:{"data-cy":'resetexportedrecords-' +
        _vm.selectedIntegration.IntegrationFriendlyName,"squared":"","variant":"dark","size":"sm"},on:{"click":function($event){return _vm.resetAllExportedRecordsForIntegration(
          _vm.selectedIntegration.IntegrationID
        )}}},[_vm._v("\n      "+_vm._s(_vm.$t(
          'TL_SHARED_OPTIONS_REEXPORT ALL_EXPORTED_TO_INTEGRATION',
          {
            integration:
              _vm.selectedIntegration.IntegrationFriendlyName,
          }
        ))+"\n    ")]):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }