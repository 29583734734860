var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-overlay',{attrs:{"show":_vm.connecting,"rounded":"sm"}},[_c('div',{directives:[{name:"b-hover",rawName:"v-b-hover",value:(_vm.handleHover),expression:"handleHover"}],staticClass:"card-block text-center"},[(
        _vm.integration.IntegrationTypeID === 1 &&
        !_vm.integration.Connected
      )?_c('span',{staticClass:"connect",attrs:{"data-cy":'integration-' +
        _vm.integration.IntegrationFriendlyName +
        -'connect'}},[_vm._v(_vm._s(_vm.$t('TL_INTEGRATIONS_SELECTOR_CONNECTED')))]):_vm._e(),_vm._v(" "),_c('div',{staticClass:"circle",class:{
        circleselected: _vm.isselected,
        circlehover: _vm.isHovered && !_vm.isselected,
      },attrs:{"data-cy":'integration-' + _vm.integration.IntegrationFriendlyName},on:{"click":function($event){return _vm.integrationClicked(_vm.integration)}}},[_c('b-row',{staticClass:"no-gutters",staticStyle:{"height":"100%"},attrs:{"align-v":"center"}},[_c('b-col',{attrs:{"cols":"12"}},[_c('img',{staticStyle:{"height":"36px","width":"36px"},attrs:{"src":require(("@/assets/images/integrations/" + (_vm.integration.IntegrationName) + ".128x128.png")),"alt":""}}),_vm._v(" "),(_vm.isselected || _vm.isHovered)?_c('div',{staticClass:"itemcheck"},[_c('font-awesome-icon',{attrs:{"icon":"check","fixed-width":""}})],1):_vm._e()])],1)],1),_vm._v(" "),_c('p',{staticClass:"card-text"},[_vm._v("\n      "+_vm._s(_vm.integration.IntegrationFriendlyName)+"\n    ")])])])}
var staticRenderFns = []

export { render, staticRenderFns }