var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-form-group',{staticClass:"mb-4",attrs:{"label-cols":"0","content-cols":"12","label-cols-md":"3","content-cols-md":"9","label":_vm.$t('TL_EXPORT_RECORD_PREVIEW'),"label-size":"lg","label-for":"selRecordPreview","label-class":"font-weight-bold pt-0"}},[_c('div',{staticClass:"card card-body bg-light"},[_c('span',[_vm._v(_vm._s(_vm.examplerecord.Content))]),_vm._v(" "),(
        _vm.selectedIntegration.IntegrationExportSettings
          .CitationStyleID !== 0
      )?_c('span',[(
          _vm.selectedIntegration.IntegrationExportSettings
            .SeparateCitationWithEmptyLine
        )?_c('br'):_vm._e(),_vm._v(" "),(
          !_vm.selectedIntegration.IntegrationExportSettings
            .SeparateCitationWithEmptyLine
        )?_c('span',[_vm._v(_vm._s(_vm.$t('TL_EXPORT_RECORD_PREVIEW_SEPARATOR')))]):_vm._e(),_vm._v(" "),(
          _vm.selectedIntegration.IntegrationExportSettings
            .CitationStyleID == 1
        )?_c('span',[_vm._v("\n        "+_vm._s(_vm.$t('TL_EXPORT_RECORD_PREVIEW_CITATION_STYLE_ONE', {
            author: _vm.examplerecord.Author,
            book: _vm.examplerecord.Book,
            page: _vm.examplerecord.Page,
            location: _vm.examplerecord.Location,
          }))+"\n      ")]):_vm._e(),_vm._v(" "),(
          _vm.selectedIntegration.IntegrationExportSettings
            .CitationStyleID == 2
        )?_c('span',[_vm._v("\n        "+_vm._s(_vm.$t('TL_EXPORT_RECORD_PREVIEW_CITATION_STYLE_TWO', {
            author: _vm.examplerecord.Author,
            book: _vm.examplerecord.Book,
            page: _vm.examplerecord.Page,
            location: _vm.examplerecord.Location,
          }))+"\n      ")]):_vm._e(),_vm._v(" "),(
          _vm.selectedIntegration.IntegrationExportSettings
            .CitationStyleID !== 0
        )?_c('span',[_vm._v("\n        "+_vm._s(_vm.$t('TL_EXPORT_RECORD_PREVIEW_CITATION_STYLE_THREE', {
            author: _vm.examplerecord.Author,
            book: _vm.examplerecord.Book,
            page: _vm.examplerecord.Page,
            location: _vm.examplerecord.Location,
          }))+"\n      ")]):_vm._e(),_vm._v(" "),(
          _vm.selectedIntegration.IntegrationExportSettings
            .CitationStyleID == 4
        )?_c('span',[_vm._v("\n        "+_vm._s(_vm.$t('TL_EXPORT_RECORD_PREVIEW_CITATION_STYLE_FOUR', {
            author: _vm.examplerecord.Author,
            book: _vm.examplerecord.Book,
            page: _vm.examplerecord.Page,
            location: _vm.examplerecord.Location,
            created: _vm.examplerecord.Created,
          }))+"\n      ")]):_vm._e()]):_vm._e(),_vm._v(" "),(_vm.selectedIntegration.IntegrationExportSettings.Notes)?_c('div',[_c('br'),_vm._v(" "),_c('em',[_vm._v(_vm._s(_vm.examplerecord.Notes))])]):_vm._e(),_vm._v(" "),(
        _vm.selectedIntegration.IntegrationExportSettings.IncludeTags
      )?_c('div',[_c('br'),_vm._v(" "),_c('em',[_vm._v(_vm._s(_vm.examplerecord.Tags))])]):_vm._e()])])}
var staticRenderFns = []

export { render, staticRenderFns }