




























































import { Component, Vue } from 'vue-property-decorator';
import ExportUiStateModule from '@/store/modules/ExportUiStateModule';
import UserSettingsModule from '@/store/modules/UserSettingsModule';
import i18n from '@/i18n';
import { CitationStyle } from '@/store/types/Enums';

@Component
export default class ExportOptionsCitations extends Vue {
  citationStyleOptions = [
    { value: 0, text: i18n.t('TL_EXPORT_RECORD_OPTIONS_NONE') },
    { value: 1, text: i18n.t('TL_EXPORT_RECORD_OPTIONS_SIMPLE') },
    { value: 2, text: i18n.t('TL_EXPORT_RECORD_OPTIONS_CHICAGO') },
    { value: 3, text: i18n.t('TL_EXPORT_RECORD_OPTIONS_APA') },
    { value: 4, text: i18n.t('TL_EXPORT_RECORD_OPTIONS_MLA') },
  ];

  get citationStyle() {
    return ExportUiStateModule.selectedIntegration!
      .IntegrationExportSettings.CitationStyleID;
  }

  set citationStyle(value: number) {
    console.log('Updating value to: ' + value);
    UserSettingsModule.saveIntegrationExportSettings({
      settingName: 'CitationStyleID',
      settingValue: value,
      modal: this.$bvModal,
      showResetPrompt: true,
    });
  }

  get IncludeCitationFields() {
    return (
      ExportUiStateModule.selectedIntegration!
        .IntegrationExportSettings.CitationStyleID !==
      CitationStyle.None
    );
  }

  get SeparateCitationWithEmptyLine() {
    return ExportUiStateModule.selectedIntegration!
      .IntegrationExportSettings.SeparateCitationWithEmptyLine;
  }

  set SeparateCitationWithEmptyLine(value: boolean) {
    UserSettingsModule.saveIntegrationExportSettings({
      settingName: 'SeparateCitationWithEmptyLine',
      settingValue: value,
      modal: this.$bvModal,
      showResetPrompt: true,
    });
  }
}
