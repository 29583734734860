








































































































import ExportUiStateModule from '@/store/modules/ExportUiStateModule';
import { Component, Vue } from 'vue-property-decorator';
import exportoptionsdocumentgranularityicons from '@/pages/export/components/ExportOptionsDocumentGranularityIcons.vue';
import UserSettingsModule from '@/store/modules/UserSettingsModule';

@Component({
  components: {
    exportoptionsdocumentgranularityicons,
  },
})
export default class ExportOptionsDocumentGranularity extends Vue {
  get StartBookWithBookTitle() {
    return ExportUiStateModule.selectedIntegration!
      .IntegrationExportSettings.StartBookWithBookTitle;
  }

  set StartBookWithBookTitle(value: boolean) {
    UserSettingsModule.saveIntegrationExportSettings({
      settingName: 'StartBookWithBookTitle',
      settingValue: value,
      modal: this.$bvModal,
      showResetPrompt: true,
    });
  }

  get StartBookOnNewPage() {
    return ExportUiStateModule.selectedIntegration!
      .IntegrationExportSettings.StartBookOnNewPage;
  }

  set StartBookOnNewPage(value: boolean) {
    debugger;
    UserSettingsModule.saveIntegrationExportSettings({
      settingName: 'StartBookOnNewPage',
      settingValue: value,
      modal: this.$bvModal,
      showResetPrompt: true,
    });
  }

  get ExportDocumentMode() {
    return ExportUiStateModule.selectedIntegration!
      .IntegrationExportSettings.ExportDocumentMode;
  }

  set ExportDocumentMode(value: number) {
    UserSettingsModule.saveIntegrationExportSettings({
      settingName: 'ExportDocumentMode',
      settingValue: value,
      modal: this.$bvModal,
      showResetPrompt: true,
    });
  }

  get resetExportedRecords() {
    return ExportUiStateModule.resetExportedRecords;
  }
  get showStartBookOnANewPage() {
    return ExportUiStateModule.selectedExportFormatIntegration!
      .SupportsSingleDocumentStartBookOnANewPage;
  }

  get showStartBookWithBookTitle() {
    return ExportUiStateModule.selectedExportFormatIntegration!
      .SupportsSingleDocumentStartBookWithBookTitle;
  }
  get selectedIntegration() {
    return ExportUiStateModule.selectedIntegration;
  }

  get finalDocumentType() {
    return ExportUiStateModule.selectedExportFormatIntegration?.IntegrationName.toUpperCase();
  }
}
