
































import { Component, Vue } from 'vue-property-decorator';

import exportoptionsintegrationautoexport from '@/pages/export/components/ExportOptionsIntegrationAutoExport.vue';
import exportoptionsintegrationpublishmode from '@/pages/export/components/ExportOptionsIntegrationPublishMode.vue';
import exportoptionsintegrationsexportedrecords from '@/pages/export/components/ExportOptionsIntegrationsExportedRecords.vue';
import exportoptionsintegrationfiletype from '@/pages/export/components/ExportOptionsIntegrationFileType.vue';
import exportoptionsdocumentgranularity from '@/pages/export/components/ExportOptionsDocumentGranularity.vue';
import exportoptionsinformation from '@/pages/export/components/ExportOptionsInformation.vue';
import exportoptionsrecordtypes from '@/pages/export/components/ExportOptionsRecordTypes.vue';
import exportoptionssorting from '@/pages/export/components/ExportOptionsSorting.vue';
import exportoptionscitations from '@/pages/export/components/ExportOptionsCitations.vue';
import exportoptionscontentpreview from '@/pages/export/components/ExportOptionsContentPreview.vue';
import exportoptionsspreadsheetpreview from '@/pages/export/components/ExportOptionsSpreadsheetPreview.vue';

import ExportUiStateModule from '@/store/modules/ExportUiStateModule';
import { IntegrationType } from '@/store/types/Enums';
@Component({
  components: {
    exportoptionsintegrationfiletype,
    exportoptionsintegrationsexportedrecords,
    exportoptionsdocumentgranularity,
    exportoptionsinformation,
    exportoptionsrecordtypes,
    exportoptionssorting,
    exportoptionscitations,
    exportoptionscontentpreview,
    exportoptionsintegrationautoexport,
    exportoptionsintegrationpublishmode,
    exportoptionsspreadsheetpreview,
  },
})
export default class ExportOptionsAdvancedFormatting extends Vue {
  get IsSpreadsheetType() {
    return ExportUiStateModule.selectedIntegration!.IsSpreadsheetType;
  }
  get IsIntegrationWhichSupportsStoringFiles() {
    return ExportUiStateModule.selectedIntegration!
      .IsIntegrationWhichSupportsStoringFiles;
  }
  get ExportDestinationIsIntegration() {
    return (
      ExportUiStateModule.selectedIntegration!.IntegrationTypeID ===
      IntegrationType.Integration
    );
  }
}
