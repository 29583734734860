var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-form-group',{staticClass:"mb-4",attrs:{"label-cols":"0","content-cols":"12","label-cols-md":"3","content-cols-md":"9","label":_vm.$t('TL_INTEGRATIONS_PUBLISH_MODE'),"label-size":"lg","label-for":"selCitationStyle","label-class":"font-weight-bold pt-0"}},[_c('b-form-group',{attrs:{"label":_vm.$t('TL_EXPORT_PUBLISH_MODE_LABEL')}},[_c('div',{staticClass:"mt-2 ml-4"},[_c('b-form-radio',{attrs:{"size":"lg","checked":_vm.ExportPublishMode,"value":1},on:{"change":function($event){_vm.ExportPublishMode = 1}}},[_vm._v("\n          "+_vm._s(_vm.$t('TL_INTEGRATION_PUBLISHMODE_APPEND', {
              documenttype: _vm.$t(
                ("TL_INTEGRATION_" + _vm.finalDocumentType + "_DOCUMENTNAME")
              ),
            })))]),_vm._v(" "),_c('div',{staticClass:"ml-4 my-4"},[_c('div',{staticClass:"d-flex flex-row align-items-center"},[_c('font-awesome-icon',{staticClass:"mr-1",attrs:{"icon":['fas', 'plus-circle'],"fixed-width":""}}),_vm._v(" "),_c('span',[_vm._v("Changes you make within Evernote are\n              "),_c('strong',[_vm._v("not")]),_vm._v(" overwritten")])],1),_vm._v(" "),_c('div',{staticClass:"d-flex flex-row align-items-center"},[_c('font-awesome-icon',{staticClass:"mr-1",attrs:{"icon":['fas', 'minus-circle'],"fixed-width":""}}),_vm._v(" "),_c('span',[_vm._v("Records are ordered by when they were highlighted\n            ")]),_vm._v(" "),_c('font-awesome-icon',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.above",value:(
                "This may be different than the order in which they \n            they appear in the book."
              ),expression:"\n                `This may be different than the order in which they \n            they appear in the book.`\n              ",modifiers:{"hover":true,"above":true}}],staticClass:"ml-2",attrs:{"icon":"info-circle","fixed-width":""}})],1)]),_vm._v(" "),_c('b-form-radio',{attrs:{"data-cy":"exportdocumentmodebookcheckbox","size":"lg","checked":_vm.ExportPublishMode,"value":2},on:{"change":function($event){_vm.ExportPublishMode = 2}}},[_vm._v("\n          "+_vm._s(_vm.$t('TL_INTEGRATION_PUBLISHMODE_OVERWRITE', {
              documenttype: _vm.$t(
                ("TL_INTEGRATION_" + _vm.finalDocumentType + "_DOCUMENTNAME")
              ),
            }))+"\n        ")]),_vm._v(" "),_c('div',{staticClass:"ml-4 my-4"},[_c('div',{staticClass:"d-flex flex-row align-items-center"},[_c('font-awesome-icon',{staticClass:"mr-1",attrs:{"icon":['fas', 'minus-circle'],"fixed-width":""}}),_vm._v(" "),_c('span',[_vm._v("Changes you make within Evernote\n              "),_c('strong',[_vm._v("are")]),_vm._v(" overwritten")])],1),_vm._v(" "),_c('div',{staticClass:"d-flex flex-row align-items-center"},[_c('font-awesome-icon',{staticClass:"mr-1",attrs:{"icon":['fas', 'plus-circle'],"fixed-width":""}}),_vm._v(" "),_c('span',[_vm._v("Records are ordered by the location they are in the\n              book\n            ")]),_vm._v(" "),_c('font-awesome-icon',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.above",value:(
                "Or the sort order you define in the export settings below"
              ),expression:"\n                `Or the sort order you define in the export settings below`\n              ",modifiers:{"hover":true,"above":true}}],staticClass:"ml-2",attrs:{"icon":"info-circle","fixed-width":""}})],1)])],1)])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }