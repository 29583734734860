























































import { Component, Vue, Prop, Emit } from 'vue-property-decorator';
import { IIntegration } from '@/store/types/IIntegrations';

@Component
export default class Integrationsselectoritem extends Vue {
  @Prop({ default: null }) readonly integration!: IIntegration;
  @Prop({ default: false }) readonly isselected!: boolean;
  isHovered = false;
  connecting = false;

  handleHover(hovered: boolean) {
    this.isHovered = hovered;
  }

  @Emit() selectintegration(integration: IIntegration) {
    return integration;
  }
  @Emit() connectexportintegration(integrationID: number) {
    return integrationID;
  }

  integrationClicked(integration: IIntegration) {
    if (
      integration.IntegrationTypeID === 1 &&
      !integration.Connected
    ) {
      this.connecting = true;
      this.connectexportintegration(integration.IntegrationID);
    } else {
      this.selectintegration(integration);
      // setTimeout(() => {
      //   var options = {
      //     container: 'explorermainid',
      //   };
      //   this.$scrollTo('#options', options);
      // }, 2000);

      // setTimeout(() => {
      //   let elmnt = document.getElementsByTagName('main')[0];
      //   //elmnt!.scrollIntoView(false);
      //   elmnt.scrollTop = elmnt.scrollHeight;
      // }, 100);
    }
  }
}
